.QuickContactIcon {
  width: 80px;
  height: 80px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
}

.QuickContactContent {
  width: 300px;
  height: 470px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
}

.QuickContactContentText {
  font-size: 16px;
}

.QuickContactContentText span {
  font-size: 12px;
}

.QuickContactContent_ArrowRight {
  font-size: 26px;
}